import { Environment } from './enums/environment.enum';

const CURR_ENV = Environment.PRODUCTION;

function returnUrl(): string {
    const url = window.location.origin;
    return url.replace('www.', '');
}

export const environment = {
    modules: {
        loyalty: false,
        vouchers: false,
        lidlSso: false,
        analytics: true,
        lostTickets: false,
        changeDate: false,
        plus: true,
    },
    campaignUuid: 'a356703e-6426-41f9-934f-7d2957f8c0f5',
    attractionsRoutingPathName: 'content-partner',
    currentEnvironment: CURR_ENV,
    project: 'COOP',
    campaignTitle: 'COOP Funtrips',
    voucherEuroAmount: 0.5,
    isSingleVoucherProject: false,
    sentryUri: 'https://b0ffeca3e2fd417eb3fcc350ef37357c@o122976.ingest.sentry.io/4505011987611648',
    enableScanner: false,
    loyaltyUri: 'https://jumbo-loyalty.prod.funtrips.io',
    contentApiUri: 'https://content-api.prod.funtrips.io',
    paymentProxyUri: 'https://payment-proxy.prod.funtrips.io',
    theme: 'qup-theme',
    baseUri: 'https://api.funtrips.io',
    assetsBaseUri: 'https://assets.queueup.eu',
    changeDateUri: 'https://checkout.prod.funtrips.io/checkout',
    useLegacyCheckout: false,
    production: true,
    tagManagerId: 'GTM-T6PMRJ7',
    cognitoProvider: 'native',
    federatedLogin: false,
    paymentMethod: {
        environment: 'live',
        payload: {
            merchant_account: 'QueueupBVECOM',
            channel: 'Web',
            country_code: 'NL',
            shopper_locale: 'nl_NL'
        }
    },
    adyenClientKey: 'live_TNZZOCWPT5BH5HRVNL7MY4ECSMY6VJ43',
    appleStoreUrl: 'https://apps.apple.com/nl/app/coop-dagje-uit/id6479598619',
    playStoreUrl: 'https://play.google.com/store/apps/details?id=nl.coop.dagjeuit',
    howDoesItWorkUrl: 'https://coop.nl/pagina/dagje-uit-spaaractie',
    newsletter: 'c2869690-cf43-4312-ab1f-21d550194610',
    deeplinkUrl: 'funtripsmobile://dagjeuitsparen.nl'
};

const awsmobile = {
    aws_project_region: 'eu-west-1',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_UREM3EwKk',
    aws_user_pools_web_client_id: '3coof22rvlnv0oa7i7bf9sl1io',
    oauth: {
        domain: 'coop-accounts-production.auth.eu-west-1.amazoncognito.com',
        scope: [
            'email',
            'openid',
            'aws.cognito.signin.user.admin',
            'profile',
            'phone'
        ],
        redirectSignIn: 'https://coop.funtrips.io',
        redirectSignOut: 'https://coop.funtrips.io/login',
        responseType: 'code'
    },
    federationTarget: 'COGNITO_USER_POOLS'
};

export default awsmobile;